import React from 'react'
import { I18n } from '@front/volcanion'

import { GridStepper, Loader } from '@front/squirtle'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Grid, Step, Fade } from '@mui/material'

import OrderHeader from '@abra/elements/headers/Order'
import FormSpeedDial from '@abra/elements/speedDial/Form'

import { GridMarginLeft } from './Grid/'
import {
  AddressSection,
  BillingSection,
  ClientSection,
  DateTimeSection,
  DriverInfoSection,
  EstimationSection,
  HistorySection,
  MapSection,
  OptionsSection,
  PackageSection,
  PaymentSection,
  RidemodeDurationSection
} from './Section'

import Dialogs from './Popup'

const OrderDetails = props => {
  const {
    isReadOnly,
    isManagingRecord,
    record_id,
    driver_id,
    commercial_package,
    canOrangeWait,
    disableHeader,
    disableHistory,
    onSubmitHandler,
    isSubmitting,
    headerLabelProps,
    handleInvoiceDownload,
    handleClickCallCenter,
    handleClickMap,
    handleNewOrder,
    handleWaitingOrange,
    handleOpenSendCommentDialog,
    handleOpenManualDispatch,
    handleOpenSendMessageDriver,
    handleOpenRedispatch,
    handleOpenClaimRedispatch,
    handleCancel,
    destination_enabled,
    duration_enabled,
    disabledSubmitButton,
    isEstimationLoading,
    canCancel,
    displayDownloadBillingButton,
  } = props

  const actions = [
    { name: I18n.t('order.new'), onClick: handleNewOrder, iconName: 'add', hidden: !(!!isManagingRecord && !!isReadOnly) },
    { name: I18n.t('central.label', { count: 1 }), onClick: handleClickCallCenter, iconName: 'call', hidden: !!record_id },
    { name: I18n.t('confirm.waiting'), onClick: handleWaitingOrange, iconName: 'phone_paused', hidden: !canOrangeWait },
    { name: I18n.t('map.label', { count: -1 }), onClick: handleClickMap, iconName: 'map_outlined', hidden: !record_id },
    { name: I18n.t('comment.label', { count: 1 }), onClick: handleOpenSendCommentDialog, iconName: 'insert_comment', hidden: !record_id },
    { name: I18n.t('driver.message'), onClick: handleOpenSendMessageDriver, iconName: 'forum', hidden: !driver_id },
    { name: I18n.t('action.redispatch'), onClick: handleOpenRedispatch, iconName: 'refresh', hidden: !driver_id },
    { name: I18n.t('action.claim'), onClick: handleOpenClaimRedispatch, iconName: 'accessibility_new', hidden: !record_id },
    { name: I18n.t('action.manual_dispatch'), onClick: handleOpenManualDispatch, iconName: 'accessibility_new', hidden: !record_id },
    { name: I18n.t('billing.label.alter'), onClick: handleInvoiceDownload, iconName: 'description', hidden: !(!!record_id && !!isReadOnly && !!displayDownloadBillingButton) },
    { name: I18n.t('action.cancel'), onClick: () => handleCancel(false), iconName: 'do_not_disturb', iconColor: 'error', hidden: !(!!record_id && !!isReadOnly) || !canCancel }
  ]

  return <>
    <Fade in timeout={2000}>
      <Box>
        <Dialogs handleOpenManualDispatch={handleOpenManualDispatch} handleCancel={handleCancel} />
        <Box sx={{ display: '-webkit-inline-box' }}>
          <Grid container rowSpacing={1}>
            <Grid container item xs={12}>
              <Grid item xs={12} hidden={isReadOnly}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '90%' }}>
                  <LoadingButton
                    onClick={onSubmitHandler}
                    loading={!!isSubmitting || !!isEstimationLoading}
                    loadingIndicator={<Loader
                      size={30}
                      isLoading={!!isSubmitting || !!isEstimationLoading}
                    />}
                    variant='contained'
                    disabled={disabledSubmitButton}
                  >
                    {I18n.t('action.save')}
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={0.8}>
                  <GridMarginLeft  {...props} />
                </Grid>
                <Grid item xs={11.2}>
                  <Grid item xs={12} hidden={!isManagingRecord || !!disableHeader}>
                    <OrderHeader order_id={record_id} headerLabelProps={headerLabelProps} />
                  </Grid>
                  <GridStepper connector={<></>} groupsCount={[4]} activeStep={0} orientation="vertical" getStepProps={(index, group) => ({ active: (group !== 1 || index !== 3) || !!isManagingRecord })}>
                    <Step> <ClientSection />  </Step>
                    <Step> <DateTimeSection /> </Step>
                    <Step> <AddressSection prefix='source' /> </Step>
                    <Step>
                      {!!destination_enabled && <AddressSection prefix='destination' />}
                      {!!duration_enabled && <RidemodeDurationSection />}
                    </Step>
                    <Step> <PaymentSection /> </Step>
                    <Step> <PackageSection /> </Step>
                    <Step> <OptionsSection /> </Step>
                  </GridStepper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ ml: 4, mt: 4 }}>
                <Grid container spacing={2} rowSpacing={2}>
                  <Grid item xs={6} hidden={!isManagingRecord}><BillingSection /></Grid>
                  <Grid container item xs={6} rowSpacing={2}>
                    <Grid item xs={12} hidden={!commercial_package}><EstimationSection /></Grid>
                    <Grid item xs={12} hidden={!isManagingRecord}><DriverInfoSection /></Grid>
                  </Grid>
                  <Grid item xs={12} hidden={!isManagingRecord || !!disableHistory}><HistorySection /></Grid>
                  <Grid item xs={12} hidden={!isManagingRecord || !!disableHistory}>
                    <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
                      <MapSection record_id={record_id} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <FormSpeedDial actions={actions} />
        </Box>
      </Box>
    </Fade>
  </>
}

export default React.memo(OrderDetails)
