import React from 'react'
import { MapFactory } from '@front/squirtle'
import { Box } from '@mui/material'

const ManualDispatch = ({
  incident_ids,
  drop_off_id,
  picked_up_id,
  transport_started_id,
  location_history_ids,
  at_source_id,
  source_id,
  destination_id,
  driver_request_for_a_not_loaded_id,
  driver_request_for_replacement_id
}) => <>
    <Box sx={{ p: 3, borderRadius: 6 }}>
      <MapFactory.MapContainer mapStyle={{ height: 800 }} mapId='order_details' fitToBounds>
        <MapFactory.SuperLayer fitToBounds followBounds boundOptions={{ animate: true }}>
          <MapFactory.Marker
            config={{
              model_name: 'address',
              ids: [source_id],
              coordinates_path: 'coordinates'
            }}
            styles={{ icon: 'flag', color: 'green' }}
          />
          <MapFactory.Marker
            config={{
              model_name: 'address',
              ids: [destination_id],
              coordinates_path: 'coordinates'
            }}
            styles={{ icon: 'flag', color: 'red' }}
          />
          <MapFactory.Marker
            config={{
              model_name: 'event',
              ids: [at_source_id],
              coordinates_path: 'coordinates'
            }}
            styles={{ icon: 'location_on', color: 'green' }}
          />
          <MapFactory.Marker
            config={{
              model_name: 'event',
              ids: [picked_up_id],
              coordinates_path: 'coordinates'
            }}
            styles={{ icon: 'login', color: 'green' }}
          />
          <MapFactory.Marker
            config={{
              model_name: 'event',
              ids: [drop_off_id],
              coordinates_path: 'coordinates'
            }}
            styles={{ icon: 'logout', color: 'red' }}
          />
          <MapFactory.Marker
            config={{
              model_name: 'event',
              ids: [transport_started_id],
              coordinates_path: 'coordinates'
            }}
            styles={{ icon: 'local_phone' }}
          />
          <MapFactory.Marker
            config={{
              model_name: 'event',
              ids: [driver_request_for_a_not_loaded_id],
              coordinates_path: 'coordinates'
            }}
            styles={{ icon: 'person_off' }}
          />
          <MapFactory.Marker
            config={{
              model_name: 'event',
              ids: [driver_request_for_replacement_id],
              coordinates_path: 'coordinates'
            }}
            styles={{ icon: 'repeat' }}
          />
          <MapFactory.Polyline
            config={{
              model_name: 'locationhistory',
              ids: location_history_ids,
              coordinates_path: 'coordinates'
            }}
            styles={{ dashArray: "4" }}
          />
        </MapFactory.SuperLayer>
      </MapFactory.MapContainer>
    </Box>
  </>
export default React.memo(ManualDispatch)