import FormatUtils from '@front/squirtle/utils/format'

const parseStatus = record => {
  const statusName = FormatUtils.getStatus(_.pick(record, ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))
  const formattedSuspension = FormatUtils.formatSuspended(
    { name: statusName, ..._.pick(record, ['suspendedSince', 'suspendedUntil', 'motive']) },
    'license'
  )
  return formattedSuspension
}

const Aux = {
  parseStatus
}

export default Aux
