import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import Callbacks from './callbacks'
import Aux from '../../Aux'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [group_id] = hooks.useFormState('group_id')
  const [vehicle_id] = hooks.useFormState('vehicle_id')
  const [status, usual_vehicle] = hooks.useFieldValues(['status', 'usual_vehicle'])
  const [, { openDialog }] = hooks.useDialogs()
  const suspendedStateName = 'suspended'
  const [, setSuspendedState] = hooks.useFormState(suspendedStateName)
  const [license] = hooks.useModel('license', [record_id], { single: true })
  const [modifyLicense] = hooks.useModelFunction('license', 'modify')

  const formattedSuspension = Aux.parseStatus(license)
  const statusName = FormatUtils.getStatus(_.pick(license, ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))
  const initialSuspendedValues = {
    name: statusName,
    motive: license?.motive,
    suspendedSince: license?.suspendedSince,
    suspendedUntil: license?.suspendedUntil
  }

  const verifyClientId = useCallback(hooks.useRecordExist('license', 'license_client_id'), [])
  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])
  const onClickRemoveSuspend = useCallback(Callbacks.onClickRemoveSuspendHandler(openDialog), [openDialog])
  const onConfirmRemoveSuspend = useCallback(Callbacks.onConfirmRemoveSuspendHandler(record_id, modifyLicense), [record_id, modifyLicense])

  const color = useMemo(() => FormatUtils.getStatusColor(status), [status])

  const mergedProps = {
    usual_vehicle,
    group_id,
    vehicle_id,
    formattedSuspension,
    isManagingRecord,
    color,
    statusChange,
    suspendedStateName,
    initialSuspendedValues,
    onClickRemoveSuspend,
    onConfirmRemoveSuspend,
    verifyClientId,
    isReadOnly,
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
