
import React from 'react'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import GroupTab from '@abra/panels/Tab/Group'

import PrimarySection from './Section/Primary'
import OptionsSection from './Section/Equipment'

const License = ({ isManagingRecord, ...props }) =>
  <Grid container item justifyContent='center' xs={9} rowSpacing={3}>
    <Grid item xs={12}>
      <GroupTab value={2} />
    </Grid>
    <Grid item xs={12} >
      <FormHeader />
      <GridStepper groupsCount={[2]} activeStep={0} orientation="vertical" getStepProps={index => ({ active: index !== 1 || !!isManagingRecord })} >
        <Step> <PrimarySection />  </Step>
        <Step> <OptionsSection /> </Step>
      </GridStepper>
    </Grid>
  </Grid>

export default React.memo(License)
