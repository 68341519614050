import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [order] = hooks.useModel('order', [record_id], {
    populate: [
      'assigned_transport.driver.info',
      'assigned_transport.vehicle.info',
      'assigned_transport.vehicle.info.model.brand',
      'assigned_transport.license',
      'rating'
    ], single: true
  })

  const [{ name: driver_name }] = hooks.useCustomerInfo(order?.assigned_transport?.driver?.user_id)

  const mergedProps = {
    driver: {
      user_id: _.get(order, 'assigned_transport.driver.user_id'),
      name: driver_name,
      client_id: _.get(order, 'assigned_transport.driver.info.user_client_id'),
      comment: _.get(order, 'assigned_transport.comment')
    },
    vehicle: {
      vehicle_id: _.get(order, 'assigned_transport.vehicle.vehicle_id'),
      group_id: _.get(order, 'assigned_transport.vehicle.group'),
      plaque: _.get(order, 'assigned_transport.vehicle.info.plaque'),
      brand: _.get(order, 'assigned_transport.vehicle.info.model.brand.name'),
      model: _.get(order, 'assigned_transport.vehicle.info.model.name')
    },
    license: {
      license_id: _.get(order, 'assigned_transport.license.license_id'),
      client_id: _.get(order, 'assigned_transport.license.license_client_id'),
      group_id: _.get(order, 'assigned_transport.license.group')
    },
    rating: {
      note: _.get(order, 'rating.rating'),
      comment: _.get(order, 'rating.comment')
    }
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
