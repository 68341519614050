import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'
import { hooks } from '@front/volcanion'

const Row = ({ row }) => {
  const [{ name: operator_name }] = hooks.useCustomerInfo(row?.operator?.user_id)
  return <>
    <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'createdAt'))} /></TableCell>
    <TableCell size={'small'}><TextCell value={operator_name} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'rubric.name_translated')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'name')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'infos')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'vehicle.info.plaque')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'driver.info.user_client_id')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'license.license_client_id')} /></TableCell>
  </>
}

export default React.memo(Row)
