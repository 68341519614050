import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid, StepLabel, StepContent, Button } from '@mui/material'
import { TextComponent, TextField, MenuSelectorField } from '@front/squirtle'
import { Description } from '@mui/icons-material'


const Row1 = ({ load_type, billing_status }) =>
  <Grid container item xs={12} spacing={3}>
    <Grid item xs>
      <MenuSelectorField
        name={'billing_status'}
        label={I18n.t('billing.status.label', { count: 1 })}
        options={_.map(billing_status, value => ({ label: I18n.t(`billing.status.${value}`), value }))}
        disabled
      />
    </Grid>
    <Grid item xs>
      <TextField name={'payment_ref'} label={I18n.t('bank.payment_ref')} disabled />
    </Grid>
    <Grid item xs>
      <TextField
        name={`load_count`}
        label={I18n.t(`load.${load_type}.count`)}
        type='number'
        InputProps={{ inputProps: { style: { textAlign: 'right' } } }}
        disabled
      />
    </Grid>
  </Grid>

const Row2 = ({ included_waiting_minute, updateBilledHandlingDuration }) =>
  <Grid container item xs={12} spacing={3}>
    <Grid item xs={4}>
      <TextField
        name={'assigned_transport.info.effective_handling_duration'}
        label={I18n.t('quotation.handling_duration')}
        disabled
        type='number'
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('minute.label.short')
        }}
        afterUserChange={updateBilledHandlingDuration}
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('quotation.included_waiting_minute')}
        name='included_waiting_minute'
        value={included_waiting_minute}
        disabled
        type='number'
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('minute.label.short')
        }}
      />
    </Grid>
    <Grid item xs={5}>
      <Box sx={{ display: 'flex' }}>
        <TextField
          label={I18n.t('quotation.billed_handling_duration')}
          name='assigned_transport.info.billed_handling_duration'
          type='number'
          disabled
          InputProps={{
            inputProps: { style: { textAlign: 'right' } },
            endAdornment: I18n.t('minute.label.short')
          }}
        />
        <Box sx={{ ml: 1 }}>
          <TextField
            label={' '}
            name={'handling'}
            type='number'
            InputProps={{
              inputProps: { style: { textAlign: 'right' } },
              endAdornment: I18n.t('fiat.eur.sign')
            }}
            disabled
          />
        </Box>
      </Box>
    </Grid>
  </Grid >

const Row3 = ({ displayDriverPackageFee }) =>
  <Grid container item xs={12} spacing={3}>
    <Grid item xs>
      <TextField
        name={'prepaid'}
        label={I18n.t('quotation.prepaid')}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('fiat.eur.sign')
        }}
        disabled
        type='number'
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'fees'}
        label={I18n.t('quotation.fees')}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('fiat.eur.sign')
        }}
        disabled
        type='number'
      />
    </Grid>
    <Grid item xs>
      <TextField
        label={I18n.t('quotation.base')}
        name='base'
        type='number'
        disabled
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('fiat.eur.sign')
        }}
      />
    </Grid>
    <Grid item xs hidden={!displayDriverPackageFee}>
      <TextField
        label={I18n.t('quotation.driver_package_fee')}
        name='driver_package_fee'
        type='number'
        disabled
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('fiat.eur.sign')
        }}
      />
    </Grid>
  </Grid>


const Row4 = ({ total }) =>
  <Grid container item xs={12} justifyContent='flex-end' alignItems='flex-end'>
    <Grid item xs={4}>
      <Box sx={{ display: 'flex', justifyContent: 'center', bgcolor: 'primary.main', fontSize: 'h6.fontSize', fontWeight: 600, color: 'white', boxShadow: 6 }}>
        <Typography sx={{ ml: 1, fontSize: 'inherit', fontWeight: 'inherit' }}> {I18n.t('quotation.total.label', { count: 1 })}: </Typography>
        <Typography sx={{ ml: 1, fontSize: 'inherit', fontWeight: 'inherit' }}> {total} </Typography>
        <Typography sx={{ ml: 1, fontSize: 'inherit', fontWeight: 'inherit' }}> {I18n.t('fiat.eur.sign')} </Typography>
      </Box>
    </Grid>
  </Grid>

const Row5 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={12}>
      <TextField name={'billing_customer_comment'} label={I18n.t('user.label', { count: 1 })} disabled />
    </Grid>
    <Grid item xs={12}>
      <TextField name={'billing_member_comment'} label={I18n.t('group.label', { count: 1 })} disabled />
    </Grid>
  </Grid>

const Row6 = ({ isDurationRideMode }) =>
  <Grid container item xs>
    <Grid item xs={4}>
      <TextField
        name={'option_amount'}
        label={I18n.t('option.amount')}
        type='number'
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('fiat.eur.sign')
        }}
      />
    </Grid>
    <Grid item xs hidden={!isDurationRideMode}>
      <TextField
        name={'duration_amount'}
        label={I18n.t('ride.duration.amount')}
        type='number'
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: I18n.t('fiat.eur.sign')
        }}
      />
    </Grid>
  </Grid>


const Row7 = ({ handleInvoiceDownload }) =>
  <Grid item xs={6} justifyContent='center' alignItems='center'>
    <Button
      sx={{ width: '100%', bgcolor: 'primary.main', color: 'white', "&:hover": { color: "primary.main", bgcolor: 'white' } }}
      size='small'
      variant='contained'
      onClick={handleInvoiceDownload}
    >
      <Description />
      <Typography sx={{ ml: 1, fontSize: 'inherit', fontWeight: 600 }}> {'Télécharger la facture'} </Typography>
    </Button>
  </Grid>

const StepBillingSection = ({
  isDurationRideMode,
  total,
  load_type,
  updateBilledHandlingDuration,
  included_waiting_minute,
  handleInvoiceDownload,
  displayDriverPackageFee,
  displayDownloadBillingButton
}) => <>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('billing.label', { count: 1 })}</Typography>
    </StepLabel>
    <StepContent>
      <Grid container spacing={1} rowSpacing={1.5}>
        <Row1 load_type={load_type} />
        <Row2 included_waiting_minute={included_waiting_minute} updateBilledHandlingDuration={updateBilledHandlingDuration} />
        <Row3 displayDriverPackageFee={displayDriverPackageFee} />
        <Row4 total={total} />
        <Row5 />
        <Row6 isDurationRideMode={isDurationRideMode} />
        {!!displayDownloadBillingButton && <Row7 handleInvoiceDownload={handleInvoiceDownload} />}
      </Grid>
    </StepContent>
  </>

const BillingSection = ({
  isDurationRideMode,
  total,
  load_type,
  updateBilledHandlingDuration,
  included_waiting_minute,
  handleInvoiceDownload,
  displayDriverPackageFee,
  displayDownloadBillingButton,
  billing_status
}) =>
  <Box sx={{ p: 1, borderRadius: 1, boxShadow: 6 }}>
    <Typography variant='h5' >{I18n.t('billing.label', { count: 1 })}</Typography>
    <Box sx={{ p: 1 }}>
      <Grid container rowSpacing={1.5}>
        <Row1 load_type={load_type} billing_status={billing_status} />
        <Row2 included_waiting_minute={included_waiting_minute} updateBilledHandlingDuration={updateBilledHandlingDuration} />
        <Row3 displayDriverPackageFee={displayDriverPackageFee} />
        <Row4 total={total} />
        <Row6 isDurationRideMode={isDurationRideMode} />
        {!!displayDownloadBillingButton && <Row7 handleInvoiceDownload={handleInvoiceDownload} />}
      </Grid>
    </Box>
  </Box>

const BillingSectionManager = ({ isStep = false, ...rest }) => !!isStep ? <StepBillingSection {...rest} /> : <BillingSection {...rest} />

export default React.memo(BillingSectionManager)
