import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'
import { DriverLink } from '@abra/elements'
import AssociatedInfoComponent from '@abra/elements/event/Data/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row, load_type }) => {
  const [{ name: operator_name }] = hooks.useCustomerInfo(row?.operator?.user_id)
  const rowStyle = { size: 'small', sx: { paddingTop: 0.5, paddingRight: 0.5 } }
  const textStyle = { fontSize: 13, textAlign: 'left' }
  const formattedEventName = row?.name === 'parcell_delivered'
    ? I18n.t(`event.parcell.${load_type}`)
    : I18n.t(`event.${row?.name}`)

  return <>
    <TableCell {...rowStyle}>
      <TextCell {...textStyle} value={FormatUtils.formatDateBackToFront(_.get(row, 'producedAt'), 'DD/MM HH:mm:ss')} />
    </TableCell>
    <TableCell {...rowStyle}>
      <TextCell {...textStyle} value={operator_name} />
    </TableCell>
    <TableCell {...rowStyle}>
      <DriverLink {...textStyle} record_id={row?.driver?.user_id} hidden={!row?.driver} />
    </TableCell>
    <TableCell  {...rowStyle}>
      <TextCell {...textStyle} value={formattedEventName} />
    </TableCell>
    <TableCell {...rowStyle}>
      <AssociatedInfoComponent {...textStyle} row={row} />
    </TableCell>
  </>

}

export default React.memo(Row)
