
class Callbacks {
  static getTotalHandler(handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount) {
    return function getTotal() {
      const total = _.compact([
        _.toNumber(handling),
        _.toNumber(base),
        _.toNumber(driver_package_fee),
        _.toNumber(fees),
        _.toNumber(prepaid),
        _.toNumber(duration_amount),
        _.toNumber(option_amount)
      ])
      return _.round(_.sum(total), 2).toFixed(2)
    }
  }

  static updateBilledHandlingDurationHandler(value) {
    return function updateBilledHandlingDuration(setFormValues, included_waiting_minute, handling_slice, handling_rate, waiting_minute_rate, load_type) {
      const calculatedBilledHandlingDuration = _.toNumber(value) - _.toNumber(included_waiting_minute)
      const new_handling = load_type === 'package'
        ? handling_slice > 0
          ? Math.ceil(calculatedBilledHandlingDuration / handling_slice * handling_rate)
          : 0
        : calculatedBilledHandlingDuration * waiting_minute_rate

      const updates = _.compact(_.flatten([
        { field: 'assigned_transport.info.billed_handling_duration', value: calculatedBilledHandlingDuration },
        { field: 'handling', value: new_handling }
      ]))
      setFormValues(updates)
    }
  }

}

export default Callbacks
