import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => {
  const cellProps = {}
  const textProps = {
    _sx: { justifyContent: 'left' },
    IconProps: { mr: 0, ml: 1 },
    iconDirection: 'right'
  }

  return <>
    <TableCell {...cellProps} sx={{ minWidth: 120 }}>
      <TextHeader  {...textProps} label={I18n.t('date.label', { count: 1 })} icon='access_time' iconDirection='right' />
    </TableCell>
    <TableCell {...cellProps} >
      <TextHeader  {...textProps} label={I18n.t('operator', { count: 1 })} icon='person' iconDirection='right' />
    </TableCell>
    <TableCell {...cellProps} sx={{ minWidth: 160 }}>
      <TextHeader  {...textProps} label={I18n.t('driver.label', { count: 1 })} icon='local_taxi' iconDirection='right' />
    </TableCell>
    <TableCell {...cellProps}>
      <TextHeader  {...textProps} label={I18n.t('event.label', { count: 1 })} icon='title' iconDirection='right' />
    </TableCell>
    <TableCell {...cellProps}>
      <TextHeader  {...textProps} label={I18n.t('info.associated', { count: 1 })} icon='text_snippet' iconDirection='right' />
    </TableCell>
  </>
}

export default React.memo(Header)
