import React from 'react'
import { I18n, hooks } from '@front/volcanion/'

import { Box, Grid, Typography } from '@mui/material'
import { TextField } from '@front/squirtle'

const CancelPopup = props => {
  const { OPERATOR_MUST_JUSTIFY_CANCEL } = hooks.useRelayFeatures(['OPERATOR_MUST_JUSTIFY_CANCEL'])
  return (
    <Box mt={2}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} hidden={!OPERATOR_MUST_JUSTIFY_CANCEL}>
          <Typography>
            {I18n.t('order.cancel_justify')}
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={!OPERATOR_MUST_JUSTIFY_CANCEL}>
          <TextField name='reason' rows={3} minRows={3} multiline variant='filled' required={!!OPERATOR_MUST_JUSTIFY_CANCEL} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CancelPopup
