import React, { useCallback } from 'react'
import { TableHeader, TableRow, SearchFrame, SearchTable, SearchForm, FormContent } from '@front/squirtle'

import Header from './Header'
import Row from './Row'
import Form from './Form'
import Callbacks from './callbacks'

const HistoryList = ({ record_id, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const populate = [
    'rubric',
    'vehicle.info',
    'driver.info',
    'license',
    'operator.info',
    'operator.customerinfo'
  ]

  return (
    <SearchFrame model_name={'action'} populate={populate} allowCreate={false} >
      <SearchForm
        submitOnMount
        disableButton
        filterToForm={filterToForm}
        formToFilter={formToFilter}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable tableProps={{ onRowClick: null }}>
        <TableHeader><Header /></TableHeader>
        <TableRow ><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  )
}

export default React.memo(HistoryList)
