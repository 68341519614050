import { I18n } from '@front/volcanion'

class Callbacks {
  static statusChangeHandler(openDialog, setSuspendedState) {
    return async function statusChange(value) {
      if (value === 'suspended') {
        openDialog('suspended_license', { title: I18n.t('suspend.license.title') })
      }
      else
        setSuspendedState({ name: value })
    }
  }

  static onClickRemoveSuspendHandler(openDialog) {
    return async function onClickRemoveSuspend() {
      openDialog('remove_license_suspend', { title: I18n.t('suspend.stop.suspend'), description: I18n.t('suspend.stop.confirm') })
    }
  }

  static onConfirmRemoveSuspendHandler(license_id, licenseModify) {
    return async function onConfirmRemoveSuspend() {
      await licenseModify({ filter: { license_id }, updates: { status: { name: 'active' } } })
    }
  }

}

export default Callbacks
